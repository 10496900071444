export const bubbleChartOptions = (generatingPDF: boolean): any => {
  const isDarkMode = generatingPDF
    ? false
    : window.matchMedia('(prefers-color-scheme: dark)').matches;

  return {
    responsive: true,
    maintainAspectRatio: false,
    skipNull: true,
    type: 'bubble',
    plugins: {
      datalabels: {
        display: true,
        align: 'center',
        anchor: 'center',
        color: isDarkMode ? 'white' : 'black',
        font: {
          family: 'Iconiq Quadraat',
          size: 14
        },
        formatter: function (value: any, context: any) {
          const datasetIndex = context.datasetIndex;
          const data = context.chart.data.datasets[datasetIndex].data;
          if (data) {
            const ruleOf40Value = data[context.dataIndex].ruleOf40;
            return `${ruleOf40Value?.toFixed(0)}%`;
          }
        }
      },

      legend: {
        onClick: () => {},
        position: 'bottom',
        align: 'start',
        labels: {
          display: false,
          font: {
            family: 'Iconiq Quadraat',
            size: 16
          },
          color: isDarkMode ? 'white' : 'black',
          borderRadius: 0,
          pointStyle: 'rect',
          usePointStyle: true,
          padding: 16
        }
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const datasetIndex = context.datasetIndex;
            const data = context.chart.data.datasets[datasetIndex].data;
            let r = data[context.dataIndex].r;
            let label = '';
            label =
              r === 10
                ? '<$25M'
                : r === 20
                ? '$25-$50M'
                : r === 30
                ? '$50-$100M'
                : r === 40
                ? '$100-$200M'
                : r === 50
                ? '>$200M'
                : '';

            return `Current ARR: ${label}`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        min: 0,
        max: 300,
        title: {
          display: true,
          text: 'YoY ARR Growth (%)',
          font: {
            family: 'Iconiq Quadraat',
            size: 12
          },
          color: isDarkMode ? 'white' : 'black'
        },
        ticks: {
          color: isDarkMode ? 'white' : 'black'
        }
      },
      y: {
        grid: {
          display: false
        },
        min: -160,
        max: 140,
        title: {
          display: true,
          text: 'FCF Margin (%)',
          font: {
            family: 'Iconiq Quadraat',
            size: 12
          },
          color: isDarkMode ? 'white' : 'black'
        },
        ticks: {
          color: isDarkMode ? 'white' : 'black'
        }
      }
    }
  };
};
