import { ILinksCardWithImage } from 'interfaces/components/linksCardWithImage';

const LinksCardWithImage = ({
  title,
  description,
  link,
  image
}: ILinksCardWithImage) => {
  return (
    <a
      className='articlePanel flex flex-col items-start gap-4 max-w-[298px] min-h-[342px] p-4 bg-white dark:bg-black rounded-lg border-none hover:shadow-lg hover:opacity-85'
      href={link}
      target='_blank'
      rel='noopener noreferrer'
      data-url={link}
      data-title={title}
    >
      <div className='w-full h-[178px]'>
        <img src={image} alt='' />
      </div>
      <p className='text-2xl font-light leading-[28.8px]'>{title}</p>
      <p className='text-base font-light text-gray-700 dark:text-white'>{description}</p>
    </a>
  );
};

export default LinksCardWithImage;
