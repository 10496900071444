import CustomTooltip from 'components/Tooltip';
import HelpIcon from 'assets/icons/help-black.svg';
import { IModalSlider } from 'interfaces/components/modalSlider';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './modalSlider.css';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

const ModalSlider = ({
  label,
  tooltip,
  value,
  onChange,
  min,
  max,
  character
}: IModalSlider) => {
  const fakeSpan = useRef<HTMLSpanElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (fakeSpan && fakeSpan.current) {
      setWidth(fakeSpan.current.offsetWidth);
    }
  }, [value]);

  const onValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    let currentString = event.target.value;
    currentString =
      currentString.charAt(currentString.length - 1) === '.'
        ? currentString.slice(0, -1)
        : currentString;
    const currentNumber = Number(currentString);
    if (!isNaN(currentNumber)) {
      if (!((min && currentNumber < min) || (max && currentNumber > max))) {
        onChange(currentString);
      }
    }
  };

  return (
    <div className='w-full flex flex-col items-start gap-2'>
      <div className='flex flex-row items-center justify-start gap-1'>
        <p className='text-base font-light'>{label}</p>
        {!!tooltip && (
          <CustomTooltip
            id={label}
            content={tooltip}
            icon={HelpIcon}
            className='w-4 h-4'
          />
        )}
      </div>
      <div className='border border-gray-400 dark:border-gray-825 border-solid w-full h-11 px-4 rounded-lg flex flex-row items-center justify-between'>
        <div className='w-[138px]'>
          <Slider
            value={Number(value)}
            onChange={onChange}
            min={min}
            max={max}
          />
        </div>
        <div className='flex flex-row items-center gap-1'>
          <span ref={fakeSpan} className='absolute opacity-0 -z-[100]'>
            {value}
          </span>
          <input
            className='min-w-[1px] h-full focus:outline-none placeholder:text-gray-500 text-right text-lg placeholder:text-lg focus:text-lg w-[30px]'
            type='text'
            value={value}
            onChange={onValueChange}
            maxLength={20}
            placeholder='0'
          />
          {!!character && (
            <p className='text-lg font-light text-gray-500'> {character}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalSlider;
