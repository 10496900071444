import HelpIcon from 'assets/icons/help-grey.svg';
import LighterGreyHelpIcon from 'assets/icons/help-lighter-grey.svg';
import ResetIcon from 'assets/icons/reset-green.svg';
import Card from 'components/Card';
import CustomTooltip from 'components/Tooltip';
import { IGrowthEnterprise } from 'interfaces/components/growthEnterprise';
import { useAppSelector } from 'store';
import { formatThousands } from 'utils/helpers';

const GrowthEnterprise = ({ onUpdate }: IGrowthEnterprise) => {
  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const companyInfo = useAppSelector(state => state.companyInfo);

  return (
    <div className='w-full flex flex-col gap-[10px] pt-[15px] pb-[25px] px-20'>
      <div className='flex flex-row w-full justify-between items-start'>
        <div className='flex flex-col items-start'>
          <div className='flex flex-row gap-1 items-center'>
            <p className='text-[22px] font-light'>
              ICONIQ Growth Enterprise Five: Your Metrics
            </p>
            <CustomTooltip
              id='enterprise-five'
              content='Our team has developed a set of metrics that can serve as goalposts for individual SaaS businesses, as well as guideposts indicating where we are in the cycle: The ICONIQ Growth Enterprise Five. Looking at these five figures—ARR Growth, Net $ Retention, Rule of 40, Net Magic Number, and ARR per FTE—can help you determine the health of your business. We often see startups include these metrics in board decks and pitches as a way of signaling growth prospects, while also using this information to support strategic planning decisions around everything from GTM strategy to team size.'
              icon={isDarkMode ? LighterGreyHelpIcon : HelpIcon}
              className='w-7 h-7'
            />
          </div>
          <a
            id='read_more'
            href='https://www.iconiqcapital.com/growth/insights/iconiq-growth-enterprise-five'
            className='underline text-gray-700 dark:text-white border-none text-base font-light'
            target='_blank'
            rel='noreferrer'
          >
            Read More
          </a>
        </div>
        <div className='flex flex-row items-center gap-1'>
          <img src={ResetIcon} alt='' />
          <p
            className='text-lg font-light cursor-pointer text-green-700'
            onClick={onUpdate}
          >
            Update Your Data
          </p>
        </div>
      </div>
      <div className='flex gap-6 items-center flex-row justify-between'>
        <Card
          value={
            companyInfo.requiredInfo.yoyARR
              ? `${companyInfo.requiredInfo.yoyARR}%`
              : 'N/A'
          }
          description='ARR Growth'
          onUpdate={onUpdate}
        />
        <Card
          value={
            companyInfo.otherInfo.netDollarRetention
              ? `${companyInfo.otherInfo.netDollarRetention}%`
              : 'N/A'
          }
          description='Net Dollar Retention'
          onUpdate={onUpdate}
        />
        <Card
          value={
            companyInfo.requiredInfo.yoyARR &&
            companyInfo.requiredInfo.fcfMargin
              ? `${
                  companyInfo.requiredInfo.yoyARR +
                  companyInfo.requiredInfo.fcfMargin
                }%`
              : 'N/A'
          }
          description='Rule of 40'
          onUpdate={onUpdate}
        />
        <Card
          value={
            companyInfo.otherInfo.netMagicNumber
              ? `${companyInfo.otherInfo.netMagicNumber}x`
              : 'N/A'
          }
          description='Net Magic Number'
          onUpdate={onUpdate}
        />
        <Card
          value={
            companyInfo.requiredInfo.currentARR &&
            companyInfo.requiredInfo.totalHeadcount
              ? `$${formatThousands(
                  companyInfo.requiredInfo.currentARR /
                    companyInfo.requiredInfo.totalHeadcount,
                  0
                )}`
              : 'N/A'
          }
          description='ARR per FTE'
          onUpdate={onUpdate}
          noBorder
        />
      </div>
    </div>
  );
};

export default GrowthEnterprise;
