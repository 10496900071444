import 'chart.js/auto';
import { Chart as ChatJS, Plugin } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChatJS.register(ChartDataLabels);

export const barStackedChartOptions = (
  companyLogoURL: string,
  generatingPDF: boolean,
  hasUpperValue: boolean = true
): any => {
  const isDarkMode = generatingPDF
    ? false
    : window.matchMedia('(prefers-color-scheme: dark)').matches;

  return {
    responsive: true,
    maintainAspectRatio: false,
    skipNull: true,
    plugins: {
      legend: {
        onClick: () => {},
        position: 'bottom',
        align: 'start',
        labels: {
          font: {
            family: 'Iconiq Quadraat',
            size: 16
          },
          color: isDarkMode ? 'white' : 'black',
          borderRadius: 0,
          pointStyle: 'rect',
          usePointStyle: true,
          padding: 16
        }
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            if (context.raw) {
              const formattedValue = parseFloat(context.raw).toFixed(0);
              return `${context.dataset.label}: ${formattedValue}%`;
            }
          }
        }
      },
      datalabels: {
        color: 'white',
        anchor: 'center',
        align: 'center',
        formatter: (value: number) =>
          value !== null ? `${value?.toFixed(0)}%` : '',
        font: {
          family: 'Iconiq Quadraat',
          size: 16
        }
      },
      customImagePlugin: {
        companyLogoURL: companyLogoURL,
        index: 5,
        hasUpperValue: hasUpperValue
      },
      totalValuePlugin: {
        isDarkMode: isDarkMode
      }
    },
    layout: {
      padding: {
        top: companyLogoURL ? 90 : 25
      }
    },
    datasets: {
      bar: {
        barThickness: 90,
        categoryPercentage: 1.0,
        barPercentage: 1.0
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          drawTicks: true,
          display: false
        },
        title: {
          display: true,
          text: 'ARR Range',
          font: {
            family: 'Iconiq Quadraat',
            size: 12
          },
          color: isDarkMode ? 'white' : 'black'
        },
        ticks: {
          display: true,
          font: {
            family: 'Iconiq Quadraat',
            size: 16
          },
          color: isDarkMode ? 'white' : 'black'
        },
        border: {
          display: false
        }
      },
      y: {
        stacked: true,
        grid: {
          drawTicks: false,
          display: false
        },
        ticks: {
          display: false
        },
        border: {
          display: false
        }
      }
    }
  };
};

//add all bar values
export const totalValuePlugin: Plugin = {
  id: 'totalValuePlugin',
  afterDatasetsDraw: (chart, args, options) => {
    const {
      ctx,
      data,
      scales: { y }
    } = chart;
    ctx.save();

    const isDarkMode = options.isDarkMode;

    const stackGroups = data.datasets.reduce(
      (groups, dataset, datasetIndex) => {
        const stack = dataset.stack || 'default';
        groups[stack] = groups[stack] || [];
        groups[stack].push(datasetIndex);
        return groups;
      },
      {} as { [key: string]: number[] }
    );

    Object.values(stackGroups).forEach(datasetIndexes => {
      data.labels?.forEach((label, index) => {
        let sum = 0;
        let lastBar: any;

        datasetIndexes.forEach(datasetIndex => {
          const datasetMeta = chart.getDatasetMeta(datasetIndex);
          const value = data.datasets[datasetIndex].data[index];

          if (
            datasetMeta.hidden !== true &&
            value !== null &&
            value !== undefined
          ) {
            sum += value as number;
            lastBar = datasetMeta.data[index];
          }
        });

        if (lastBar) {
          ctx.font = 'light 19px arial';
          ctx.fillStyle = isDarkMode ? 'white' : 'black';
          ctx.textAlign = 'center';
          ctx.fillText(`${sum.toFixed(0)}%`, lastBar.x, lastBar.y - 10);
        }
      });
    });

    ctx.restore();
  }
};
