import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ICompanyDetails,
  ICompanyInfo,
  IOtherInfo,
  IRequiredInfo
} from 'interfaces/store';

const initialState: ICompanyInfo = {
  companyDetails: {
    status: null
  },
  requiredInfo: {
    currentARR: null,
    yoyARR: null,
    fcfMargin: null,
    totalHeadcount: null
  },
  otherInfo: {
    companyWebsite: '',
    grossMargin: null,
    netDollarRetention: null,
    netMagicNumber: null,
    burnMultiple: null,
    quickRatio: null,
    companyColor: '',
    rD: null,
    gA: null,
    sM: null,
    rDHead: null,
    gAHead: null,
    sMHead: null,
    companyLogoURL: localStorage.getItem('companyLogoURL') || null
  },
  email: '',
  isEntered: false
};

const companyInfoSlice = createSlice({
  name: 'companyInfo',
  initialState: initialState,
  reducers: {
    setCompanyDetails(state, action: PayloadAction<ICompanyDetails>) {
      state.companyDetails = action.payload;
    },
    setRequiredInfo(state, action: PayloadAction<IRequiredInfo>) {
      state.requiredInfo = action.payload;
    },
    setOtherInfo(state, action: PayloadAction<IOtherInfo>) {
      state.otherInfo = action.payload;
    },
    setCompanyEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setIsEntered(state, action: PayloadAction<boolean>) {
      state.isEntered = action.payload;
    },
    resetCompanyInfo() {
      return {
        companyDetails: {
          status: null
        },
        requiredInfo: {
          currentARR: null,
          yoyARR: null,
          fcfMargin: null,
          totalHeadcount: null
        },
        otherInfo: {
          companyWebsite: '',
          grossMargin: null,
          netDollarRetention: null,
          netMagicNumber: null,
          burnMultiple: null,
          quickRatio: null,
          companyColor: '',
          rD: null,
          gA: null,
          sM: null,
          rDHead: null,
          gAHead: null,
          sMHead: null,
          companyLogoURL: null
        },
        email: '',
        isEntered: false
      };
    }
  }
});

export const {
  setCompanyDetails,
  setRequiredInfo,
  setOtherInfo,
  setCompanyEmail,
  setIsEntered,
  resetCompanyInfo
} = companyInfoSlice.actions;

export default companyInfoSlice.reducer;
