import { useClickAway } from '@uidotdev/usehooks';
import Button from 'components/Button';
import { Filter } from 'components/Filter';
import { IFiltersSection } from 'interfaces/components/filtersSection';
import { useEffect, useState } from 'react';
import {
  growthMotionFilterOptions,
  primaryCustomerFilterOptions,
  softwareSectorFilterOptions
} from 'utils/constants';

const FiltersSection = ({ handleApply }: IFiltersSection) => {
  const [arrFilter, setArrFilter] = useState([]);
  const [softwareSectorFilter, setSoftwareSectorFilter] = useState([]);
  const [growthMotionFilter, setGrowthMotionFilter] = useState([]);
  const [primaryCustomerFilter, setPrimaryCustomerFilter] = useState([]);

  useEffect(() => {
    if (window.sessionStorage.getItem('software_sector'))
      setSoftwareSectorFilter(
        JSON.parse(window.sessionStorage.getItem('software_sector') || '')
      );

    if (window.sessionStorage.getItem('growth_motion'))
      setGrowthMotionFilter(
        JSON.parse(window.sessionStorage.getItem('growth_motion') || '')
      );

    if (window.sessionStorage.getItem('primary_customer'))
      setPrimaryCustomerFilter(
        JSON.parse(window.sessionStorage.getItem('primary_customer') || '')
      );
    if (window.sessionStorage.getItem('is_filters_applied') === 'true')
      handleApply(
        arrFilter,
        window.sessionStorage.getItem('software_sector')
          ? JSON.parse(window.sessionStorage.getItem('software_sector') || '')
          : softwareSectorFilter,
        window.sessionStorage.getItem('growth_motion')
          ? JSON.parse(window.sessionStorage.getItem('growth_motion') || '')
          : growthMotionFilter,
        window.sessionStorage.getItem('primary_customer')
          ? JSON.parse(window.sessionStorage.getItem('primary_customer') || '')
          : primaryCustomerFilter
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSoftwareSectorChange = (value: any) => {
    setSoftwareSectorFilter(value);
  };

  const onGrowthMotionChange = (value: any) => {
    setGrowthMotionFilter(value);
  };

  const onPrimaryCustomerChange = (value: any) => {
    setPrimaryCustomerFilter(value);
  };

  // const arrRef = useClickAway<HTMLInputElement>(() => {
  //   const parent = document.getElementsByClassName('dropdown-container')[0];
  //   const elem = document.getElementsByClassName('dropdown-heading')[0];
  //   elem &&
  //     parent &&
  //     parent.ariaExpanded === 'true' &&
  //     (elem as HTMLElement).click();
  // });

  const softwareSectorRef = useClickAway<HTMLInputElement>(() => {
    const parent = document.getElementsByClassName('dropdown-container')[0];
    const elem = document.getElementsByClassName('dropdown-heading')[0];
    elem &&
      parent &&
      parent.ariaExpanded === 'true' &&
      (elem as HTMLElement).click();
  });

  const growthMotionRef = useClickAway<HTMLInputElement>(() => {
    const parent = document.getElementsByClassName('dropdown-container')[1];
    const elem = document.getElementsByClassName('dropdown-heading')[1];
    elem &&
      parent &&
      parent.ariaExpanded === 'true' &&
      (elem as HTMLElement).click();
  });

  const primaryCustomerRef = useClickAway<HTMLInputElement>(() => {
    const parent = document.getElementsByClassName('dropdown-container')[2];
    const elem = document.getElementsByClassName('dropdown-heading')[2];
    elem &&
      parent &&
      parent.ariaExpanded === 'true' &&
      (elem as HTMLElement).click();
  });

  const onhandleApply = () => {
    window.sessionStorage.setItem('is_filters_applied', JSON.stringify(true));
    window.sessionStorage.setItem(
      'software_sector',
      JSON.stringify(softwareSectorFilter)
    );
    window.sessionStorage.setItem(
      'growth_motion',
      JSON.stringify(growthMotionFilter)
    );
    window.sessionStorage.setItem(
      'primary_customer',
      JSON.stringify(primaryCustomerFilter)
    );
    handleApply(
      arrFilter,
      softwareSectorFilter,
      growthMotionFilter,
      primaryCustomerFilter
    );
  };

  const onResetFilters = () => {
    setArrFilter([]);
    setSoftwareSectorFilter([]);
    setGrowthMotionFilter([]);
    setPrimaryCustomerFilter([]);
    window.sessionStorage.setItem('software_sector', '');
    window.sessionStorage.setItem('growth_motion', '');
    window.sessionStorage.setItem('primary_customer', '');
    window.sessionStorage.setItem('is_filters_applied', JSON.stringify(false));
    handleApply([], [], [], []);
  };

  return (
    <div className='w-full flex flex-row justify-between items-end gap-6 rounded-2xl filters'>
      {/* <Filter
        id='arr_filter'
        label='ARR'
        options={arrFilterOptions}
        value={arrFilter}
        onChange={setArrFilter}
        ref={arrRef}
      /> */}
      <Filter
        id='software_sector_filter'
        label='Software Sector'
        options={softwareSectorFilterOptions}
        value={softwareSectorFilter}
        onChange={onSoftwareSectorChange}
        ref={softwareSectorRef}
      />
      <Filter
        id='growth_motion_filter'
        label='Growth Motion'
        options={growthMotionFilterOptions}
        value={growthMotionFilter}
        onChange={onGrowthMotionChange}
        ref={growthMotionRef}
      />
      <Filter
        id='primary_customer_filter'
        label='Primary Customer Segment'
        options={primaryCustomerFilterOptions}
        value={primaryCustomerFilter}
        onChange={onPrimaryCustomerChange}
        ref={primaryCustomerRef}
      />
      <Button
        label='Reset Filters'
        id='resetFilters'
        onClick={onResetFilters}
        className='w-[129px] h-10 rounded-5xl bg-transparent border border-solid border-gray-500 text-lg font-light text-center hover:bg-[#504f4f1b] transition-all duration-300'
      />
      <Button
        label='Apply'
        id='applyFilters'
        onClick={onhandleApply}
        className='w-21 h-10 rounded-5xl bg-transparent border border-solid border-gray-500 text-lg font-light text-center text-white bg-black dark:bg-white dark:text-black hover:bg-[#404040] dark:hover:bg-gray-400 transition-all duration-300'
      />
    </div>
  );
};

export default FiltersSection;
