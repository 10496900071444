interface LoadingComponentProps {
  errorMessage: string | null;
}

const LoadingComponent = ({ errorMessage }: LoadingComponentProps) => {
  const verbose_msg = process.env.VERBOSE_MESSAGING ?
    (<p className='text-[20px] text-red-300'>Error: {errorMessage}</p>)
    : ''

  return (
    <div className='w-full h-[450px] bg-white dark:bg-black grid place-content-center text-center'>
      {errorMessage ? (
        <div className='flex flex-col justify-center items-center '>
          <div
            data-tooltip-id='info-chart'
            className='size-20 rounded-full border-[4px] grid place-content-center border-red-300 text-red-300 font-sans cursor-pointer text-[48px]'
          >
            !
          </div>
          <p className='text-[20px] mt-10'>
            An unexpected error occurred. Please try again later.
          </p>
          {verbose_msg}
        </div>
      ) : (
        <>
          <div className='animate-spin  h-16 w-16  border-[6px] border-[#d7d6d5] border-t-green-400 rounded-full '></div>
          <p className='mt-4'>Loading...</p>
        </>
      )}
    </div>
  );
};

export default LoadingComponent;
