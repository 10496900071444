import { Document, Font, pdf } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import { IChartData } from 'interfaces/chartData';
import { saveAs } from 'file-saver';
import { ChartPage } from './chartPage';
import { LastPage } from './lastPage';
import font from 'assets/fonts/Quadraat-Light-ICONIQ.ttf';
import { InfoPage } from './infoPage';
import DownloadIcon from 'components/SectionCharts/DownloadIcon';
import BlackDownloadIcon from 'components/SectionCharts/BlackDownloadIcon';
// import { LoaderIcon } from './loaderIcon';
import { useAppSelector } from 'store';
import { FilterOption } from 'interfaces/components/filter';
import { getCurrentDateFormatted } from 'utils/helpers';
import LoadingModal from 'components/LoadingModal';
import CustomTooltip from 'components/Tooltip';
import ShareIcon from 'assets/icons/share.svg';
import ShareWhiteIcon from 'assets/icons/share-white.svg';
import { downloadPDF } from 'network/downloadPDF';
import { toast } from 'react-toastify';
import Chart from 'chart.js/auto';
import { chartTitles } from 'utils/constants';

interface IDashboardPdf {
  chartData: IChartData[];
  onDownload: () => void;
  onDownloadDone: () => void;
  arr: FilterOption[];
  growthMotion: FilterOption[];
  softwareSector: FilterOption[];
  primaryCustomer: FilterOption[];
  loading: boolean;
  isCompanyDataEntered?: boolean;
}

export const DashboardPdf = ({
  onDownload,
  onDownloadDone,
  growthMotion,
  softwareSector,
  primaryCustomer,
  loading,
  isCompanyDataEntered
}: IDashboardPdf) => {
  const companyInfo = useAppSelector(state => state.companyInfo);
  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

  const exportChartToPNG = (chartElement: HTMLCanvasElement) => {
    const chart = Chart.getChart(chartElement);
    if (chart) {
      return chart.toBase64Image('image/png', 1);
    }
    return null;
  };

  const div2PDF = async () => {
    onDownload();
    document.body.classList.add('!overflow-hidden');

    const data = {
      uuid:
        sessionStorage.getItem('uuid') || localStorage.getItem('uuid') || '',
      url: window.location.href,
      isDownload: true
    };

    try {
      await downloadPDF(data);
      toast.success('PDF download started', {
        hideProgressBar: true
      });
    } catch (error) {
      toast.error('Failed to download PDF', {
        hideProgressBar: true
      });
    }

    setTimeout(async () => {
      let inputs: HTMLCollectionOf<Element> =
        window.document.getElementsByClassName('div2PDF');
      let chartWidth = 0;

      for (let i = 0; i < inputs.length; i++) {
        let currWidth = Number(
          (inputs[i] as HTMLElement).style.width.replace('px', '')
        );
        if (currWidth > chartWidth) {
          chartWidth = currWidth;
        }
      }

      for (let i = 0; i < inputs.length; i++) {
        (inputs[i] as HTMLElement).style.width = chartWidth + 'px';
      }

      const canvasPromises = Array.from(inputs).map(async (input, index) => {
        const canvas = await html2canvas(input as HTMLElement, {
          scale: 4,
          useCORS: true,
          logging: false
        });

        const svgImage = exportChartToPNG(input as HTMLCanvasElement);

        return {
          canvas: canvas,
          svg: svgImage,
          index: index
        };
      });

      const canvasResults = await Promise.all(canvasPromises);

      Font.register({
        family: 'Iconiq Quadraat',
        fonts: [{ src: font }]
      });

      const MyDocument = (
        <Document style={{ fontFamily: 'Iconiq Quadraat' }}>
          <InfoPage companyInfo={companyInfo} />
          {canvasResults.map((result, i) => (
            <ChartPage
              key={i}
              image={result.svg as string}
              title={chartTitles[result.index]}
              pageNumber={i + 2}
              growthMotion={growthMotion}
              primaryCustomer={primaryCustomer}
              softwareSector={softwareSector}
            />
          ))}
          <LastPage />
        </Document>
      );

      const pdfBlob = await pdf(MyDocument).toBlob();
      saveAs(
        pdfBlob,
        `ICONIQ Growth Compass Benchmarks ${getCurrentDateFormatted()}.pdf`
      );

      onDownloadDone();
      document.body.classList.remove('!overflow-hidden');
    }, 500);
  };

  const onShareURLClick = () => {
    navigator.clipboard.writeText(document.URL);
  };

  return (
    <div className='flex flex-row items-center gap-4'>
      {isCompanyDataEntered && (
        <div>
          <button
            className='bg-black dark:bg-white flex items-center gap-2 px-5 h-[35px] rounded-full hover:opacity-90'
            onClick={div2PDF}
            id='download_pdf_button'
            disabled={loading}
          >
            {isDarkMode ? <BlackDownloadIcon /> : <DownloadIcon />}
            <span className='text-white dark:text-black text-base font-light'>
              Download Your Benchmarks
            </span>
          </button>
          <LoadingModal
            isOpen={loading}
            onClose={() => {}}
            onReset={() => {}}
          />
        </div>
      )}
      <CustomTooltip
        id='share_button'
        content='Share your benchmarks! Click this button to copy a shareable URL to your clipboard'
        icon={isDarkMode ? ShareWhiteIcon : ShareIcon}
        onClick={onShareURLClick}
        className='w-[35px] cursor-pointer border border-solid border-gray-400 dark:border-gray-650 rounded-full p-[6px] shareable_url'
      />
    </div>
  );
};
