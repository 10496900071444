const DownloadIcon = () => {
    return (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask
          id='mask0_452_1760'
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='20'
          height='20'
        >
          <rect width='20' height='20' fill='#fff' />
        </mask>
        <g mask='url(#mask0_452_1760)'>
          <path
            d='M10 13.1571L6.44229 9.59937L7.32063 8.69563L9.375 10.75V3.75H10.625V10.75L12.6794 8.69563L13.5577 9.59937L10 13.1571ZM5.25646 16.25C4.83549 16.25 4.47917 16.1042 4.1875 15.8125C3.89583 15.5208 3.75 15.1645 3.75 14.7435V12.484H5V14.7435C5 14.8077 5.02674 14.8665 5.08021 14.9198C5.13354 14.9733 5.19229 15 5.25646 15H14.7435C14.8077 15 14.8665 14.9733 14.9198 14.9198C14.9733 14.8665 15 14.8077 15 14.7435V12.484H16.25V14.7435C16.25 15.1645 16.1042 15.5208 15.8125 15.8125C15.5208 16.1042 15.1645 16.25 14.7435 16.25H5.25646Z'
            fill='black'
          />
        </g>
      </svg>
    );
  };
  
  export default DownloadIcon;
  