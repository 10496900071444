import Button from 'components/Button';
import CustomTooltip from 'components/Tooltip';
import { ITextField } from 'interfaces/components/textField';

const TextField = ({
  name,
  value,
  onChange,
  icon,
  buttonLabel,
  onClick,
  placeholder,
  disabled,
  color,
  buttonIcon,
  id,
  tooltip,
  readOnly,
  className
}: ITextField) => {
  return (
    <div
      className={`border border-gray-400 dark:border-gray-700 border-solid w-full max-w-[608px] h-12 ${
        tooltip?.id ? 'pl-1' : 'pl-5'
      } pr-1 py-1 rounded-full flex flex-row items-center !bg-[${color}] ${className}`}
      style={{ background: color }}
    >
      <div className='flex flex-row items-center gap-1 w-16'>
        {!!tooltip?.id && <CustomTooltip className='w-4 h-4' {...tooltip} />}
        {icon && <img src={icon} alt='' />}
      </div>
      <input
        className={`w-full h-full focus:outline-none px-2 placeholder:text-gray-500 dark:placeholder:text-white !bg-[${color}] text-lg placeholder:text-lg focus:text-lg`}
        type='text'
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{ background: color }}
        readOnly={readOnly}
      />
      {buttonLabel ? (
        <Button
          id={id}
          className='px-4 text-lg h-10 bg-green-500 rounded-[200px] disabled:bg-gray-500 disabled:cursor-not-allowed disabled:text-white hover:bg-[#62c77d] transition-all duration-300'
          label={buttonLabel}
          onClick={onClick}
          disabled={disabled}
        />
      ) : (
        <img src={buttonIcon} alt='' />
      )}
    </div>
  );
};

export default TextField;
