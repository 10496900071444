import { forwardRef, Ref } from 'react';
import { IUploadImage } from 'interfaces/components/uploadImage';
import UploadIcon from 'assets/icons/upload2.svg';
import { fileTypes } from 'utils/constants';

const UploadImage = forwardRef(
  ({ onClick, companyLogoURL, onImageUpload }: IUploadImage, ref: Ref<any>) => {
    return (
      <div
        className='relative cursor-pointer rounded-full w-16 h-16 flex items-center justify-center overflow-hidden'
        onClick={onClick}
      >
        {companyLogoURL ? (
          <div className='w-full h-full rounded-full overflow-hidden flex items-center justify-center border border-gray-200'>
            <div className='w-[calc(100%-8px)] h-[calc(100%-8px)] rounded-full overflow-hidden  flex items-center justify-center'>
              <img
                src={companyLogoURL}
                alt='Company Logo'
                className='w-full h-full object-contain'
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%'
                }}
              />
            </div>
          </div>
        ) : (
          <div className='w-full h-full rounded-full bg-gray-200 border border-gray-400 flex items-center justify-center'>
            <img src={UploadIcon} alt='Upload Icon' className='w-6 h-6' />
          </div>
        )}

        <input
          ref={ref}
          name='company_logo'
          type='file'
          className='hidden'
          onChange={onImageUpload}
          accept={fileTypes.join(', ')}
        />
      </div>
    );
  }
);

export default UploadImage;
