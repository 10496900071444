import ReactModal from 'react-modal';
import { IWarningModal } from 'interfaces/components/warningModal';
import CloseIcon from 'assets/icons/close.svg';
import CloseWhiteIcon from 'assets/icons/close-white.svg';
import Button from 'components/Button';

const WarningModal = ({ isOpen, onClose }: IWarningModal) => {
  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldFocusAfterRender={false}
      overlayClassName='fixed inset-0 bg-gray-500/75 z-[100]'
      className='absolute bg-white dark:bg-black dark:text-white w-[300px] max-h-[200px] left-0 right-0 top-6 bottom-6 m-auto my-auto rounded-lg'
    >
      <div className='w-full h-full flex flex-col items-center justify-between p-4'>
        <img
          className='cursor-pointer self-end'
          src={isDarkMode ? CloseWhiteIcon : CloseIcon}
          alt=''
          onClick={onClose}
        />
        <p className='text-base font-light'>
          Big data requires a big screen. This application is best viewed on a
          bigger screen.
        </p>
        <Button
          label='Close'
          id='closeWarningButton'
          onClick={onClose}
          className='self-end w-[129px] h-10 rounded-5xl bg-transparent border border-solid border-gray-500 text-lg font-light  text-center hover:bg-[#504f4f1b] transition-all duration-300'
        />
      </div>
    </ReactModal>
  );
};

export default WarningModal;
