import BuildingEngineeringImage from 'assets/images/Building_Eng_and_Product_Teams.png';
import LeveragingChannelImage from 'assets/images/Channel_Partnerships.png';
import GTMReportingImage from 'assets/images/GTM_Reporting_Guide.webp';
import HCITPathIPOImage from 'assets/images/HCIT_Path_to_IPO.webp';
import ProductLedGrowthImage from 'assets/images/PLG_Companion.webp';
import DecodingSaasIPOImage from 'assets/images/Path_to_IPO.png';
import PipelineImage from 'assets/images/Pipeline_Leads_Top_of_Funnel.webp';
import SalesCompensationImage from 'assets/images/Sales_Compensation.webp';
import ScalingTo50Image from 'assets/images/Scaling_from_0_to_50M.webp';
import GTMStateImage from 'assets/images/State_of_GTM_2024.png';
import VerticalSaasImage from 'assets/images/Vertical_SaaS.webp';
import EnterpriseGrowthImage from 'assets/images/enterprisefive.png';
import EfficientGrowthImage from 'assets/images/scaling_SaaS.png';
import { FilterOption } from 'interfaces/components/filter';
import { IPriority, PriorityItem } from 'interfaces/constants';

export const yourCompanyColor = '#5656B1';
export const topQuartileColor = '#4DB96B';
export const medianColor = '#70A7B2';
export const labelTopQuatile = 'Compass SaaS Benchmark Top Quartile';
export const labelMedian = 'Compass SaaS Benchmark Median';
export const labelYourCompany = "Your Company's Data";

export const chartTitles = [
  'YoY ARR Growth',
  'Net Dollar Retention',
  'Net Magic Number',
  'Gross Margin',
  'Rule of 40',
  'Burn Multiple',
  'ARR per FTE',
  'OpEx as a % of Revenue',
  'FCF Margin',
  'Quick Ratio',
  'Headcount Distribution'
];

export const arrFilterOptions: FilterOption[] = [
  { label: '<$25M', value: '<$25M' },
  { label: '$ 25M-$ 50M', value: '$25-$50M' },
  { label: '$ 50M-$ 100M', value: '$50-$100M' },
  { label: '$ 100M-$ 2000M', value: '$100-$200M' },
  { label: '> $ 200M', value: '>$200M' }
];

export const arrPriority: IPriority = {
  '< $ 10M': [
    {
      image: ScalingTo50Image,
      title: 'Scaling to $50M',
      description: 'Benchmarks for early-stage SaaS companies',
      link: 'https://www.iconiqcapital.com/growth/insights/growth-and-efficiency-early-stage',
      globalPriority: 3,
      localPriority: 1
    },
    {
      image: PipelineImage,
      title: 'Pipeline, Leads, and Top of Funnel Template',
      description:
        'Track and report on monthly and quarterly top of funnel, lead, and pipeline metrics',
      link: 'https://www.iconiqcapital.com/growth/insights/go-to-market-series-pipeline-leads-and-top-of-funnel-template',
      globalPriority: 3,
      localPriority: 2
    }
  ],
  '$ 10M-$ 25M': [
    {
      image: ScalingTo50Image,
      title: 'Scaling to $50M',
      description: 'Benchmarks for early-stage SaaS companies',
      link: 'https://www.iconiqcapital.com/growth/insights/growth-and-efficiency-early-stage',
      globalPriority: 3,
      localPriority: 1
    },
    {
      image: PipelineImage,
      title: 'Pipeline, Leads, and Top of Funnel Template',
      description:
        'Track and report on monthly and quarterly top of funnel, lead, and pipeline metrics',
      link: 'https://www.iconiqcapital.com/growth/insights/go-to-market-series-pipeline-leads-and-top-of-funnel-template',
      globalPriority: 3,
      localPriority: 2
    }
  ],
  '$ 25M-$ 50M': [
    {
      image: ScalingTo50Image,
      title: 'Scaling to $50M',
      description: 'Benchmarks for early-stage SaaS companies',
      link: 'https://www.iconiqcapital.com/growth/insights/growth-and-efficiency-early-stage',
      globalPriority: 3,
      localPriority: 1
    },
    {
      image: PipelineImage,
      title: 'Pipeline, Leads, and Top of Funnel Template',
      description:
        'Track and report on monthly and quarterly top of funnel, lead, and pipeline metrics',
      link: 'https://www.iconiqcapital.com/growth/insights/go-to-market-series-pipeline-leads-and-top-of-funnel-template',
      globalPriority: 3,
      localPriority: 2
    }
  ],
  '$ 50M-$ 100M': [
    {
      image: GTMReportingImage,
      title: 'The Go-to-Market Reporting Guide',
      description:
        'Key metrics and frameworks for GTM organizations to track and leverage, including templates for best-in-class reporting',
      link: 'https://www.iconiqcapital.com/growth/insights/the-go-to-market-reporting-guide',
      globalPriority: 3,
      localPriority: 1
    },
    {
      image: SalesCompensationImage,
      title: 'Sales Compensation',
      description: 'The definitive guide to Sales Compensation',
      link: 'https://www.iconiqcapital.com/growth/insights/2023-definitive-guide-to-sales-compensation',
      globalPriority: 3,
      localPriority: 2
    }
  ],
  '$ 100M-$ 150M': [
    {
      image: GTMReportingImage,
      title: 'The Go-to-Market Reporting Guide',
      description:
        'Key metrics and frameworks for GTM organizations to track and leverage, including templates for best-in-class reporting',
      link: 'https://www.iconiqcapital.com/growth/insights/the-go-to-market-reporting-guide',
      globalPriority: 3,
      localPriority: 1
    },
    {
      image: SalesCompensationImage,
      title: 'Sales Compensation',
      description: 'The definitive guide to Sales Compensation',
      link: 'https://www.iconiqcapital.com/growth/insights/2023-definitive-guide-to-sales-compensation',
      globalPriority: 3,
      localPriority: 2
    }
  ],
  '$ 150M-$ 200M': [
    {
      image: GTMReportingImage,
      title: 'The Go-to-Market Reporting Guide',
      description:
        'Key metrics and frameworks for GTM organizations to track and leverage, including templates for best-in-class reporting',
      link: 'https://www.iconiqcapital.com/growth/insights/the-go-to-market-reporting-guide',
      globalPriority: 3,
      localPriority: 1
    },
    {
      image: SalesCompensationImage,
      title: 'Sales Compensation',
      description: 'The definitive guide to Sales Compensation',
      link: 'https://www.iconiqcapital.com/growth/insights/2023-definitive-guide-to-sales-compensation',
      globalPriority: 3,
      localPriority: 2
    }
  ],
  '> $ 200M': [
    {
      image: DecodingSaasIPOImage,
      title:
        'Decoding the SaaS IPO Landscape: The Metrics that Matter and the Market Realities of 2024 and Beyond',
      description:
        'Analyzing 115 software IPOs over the past decade, our report identifies the impact of macroeconomic factors, changing investor preferences, and the evolving metrics shaping the success of companies seeking public offerings in the current environment',
      link: 'https://www.iconiqcapital.com/growth/insights/decoding-the-saas-ipo-landscape-the-metrics-that-matter-and-the-market-realities-of-2024-and-beyond',
      globalPriority: 3,
      localPriority: 1
    },
    {
      image: BuildingEngineeringImage,
      title: 'Building Engineering and Product Teams',
      description:
        'Building Engineering and Product Teams analyzes the organizational structure and composition of modern-day engineering organizations in 2024. The report focuses on headcount ratios, the sequencing of key hires, workforce arrangements, talent attrition, and more',
      link: 'https://www.iconiqcapital.com/growth/reports/engineering-series-2024/building-engineering-teams',
      globalPriority: 3,
      localPriority: 2
    }
  ]
};

export const softwareSectorFilterOptions: FilterOption[] = [
  { label: 'Infrastructure & Security', value: 'Infrastructure' },
  { label: 'Vertical application', value: 'Vertical SaaS' },
  { label: 'Horizontal application', value: 'Horizontal SaaS' }
  // { label: 'B2B Fintech', value: 'Fintech' },
  // { label: 'HCIT', value: '"Healthcare"' }
];

export const softwareSectorPriority: IPriority = {
  'Vertical application': [
    {
      image: VerticalSaasImage,
      title: 'The Vertical SaaS Companion Guide',
      description: 'Benchmarks for vertical SaaS businesses',
      link: 'https://www.iconiqcapital.com/growth/insights/growth-and-efficiency-vertical-saas',
      globalPriority: 1,
      localPriority: 1
    }
  ],
  HCIT: [
    {
      image: HCITPathIPOImage,
      title: 'The Path to IPO: 2022 Healthcare IT Version',
      description:
        'Key insights on the significant drivers of performance at IPO, the rise of consumer-driven health, data and analytics, and telehealth IPOs in 2021',
      link: 'https://www.iconiqcapital.com/growth/insights/the-path-to-ipo-2022-healthcare-it-version',
      globalPriority: 1,
      localPriority: 1
    }
  ]
};

export const growthMotionFilterOptions: FilterOption[] = [
  { label: 'Product-Led Growth', value: 'Product-led Growth' },
  { label: 'Sales-Led Growth', value: 'Sales-led Growth' }
];

export const growthMotionPriority: IPriority = {
  'Sales-Led Growth': [
    {
      image: LeveragingChannelImage,
      title: 'Leveraging Channel Partnerships to Reignite Growth',
      description:
        'Insights from channel development leaders as well as benchmarks for building channel motions from the broader tech ecosystem',
      link: 'https://www.iconiqcapital.com/growth/insights/leveraging-channel-partnerships-to-reignite-growth',
      globalPriority: 2,
      localPriority: 1
    },
    {
      image: GTMStateImage,
      title: 'The State of Go-to-Market in 2024',
      description:
        'Leading and lagging indicators of GTM health, with a focus on year over year trends and shifts to go-to-market strategy',
      link: 'https://www.iconiqcapital.com/growth/insights/the-state-of-go-to-market-in-2024',
      globalPriority: 2,
      localPriority: 2
    }
  ],
  'Product-Led Growth': [
    {
      image: ProductLedGrowthImage,
      title: 'Product-Led Growth Companion Guide',
      description: 'Benchmarks for PLG SaaS companies',
      link: 'https://www.iconiqcapital.com/growth/insights/growth-and-efficiency-plg',
      globalPriority: 2,
      localPriority: 1
    },
    {
      image: GTMStateImage,
      title: 'The State of Go-to-Market in 2024',
      description:
        'Leading and lagging indicators of GTM health, with a focus on year over year trends and shifts to go-to-market strategy',
      link: 'https://www.iconiqcapital.com/growth/insights/the-state-of-go-to-market-in-2024',
      globalPriority: 2,
      localPriority: 2
    }
  ]
};

export const primaryCustomerFilterOptions: FilterOption[] = [
  { label: 'SMB to Mid-Market', value: 'SMB to Mid-Market' },
  { label: 'Mid-Market to Enterprise', value: 'Mid-Market to Enterprise' }
];

export enum modalSections {
  COMPANY_DETAILS = 'company_details',
  REQUIRED_INFORMATION = 'required_information',
  OTHER_INFORMATION = 'other_information'
}

export enum companyStatus {
  PUBLIC = 'public',
  PRIVATE = 'private'
}

export enum modalModes {
  ADD = 'add',
  EDIT = 'edit'
}

export const defaultPriority: PriorityItem[] = [
  {
    image: EfficientGrowthImage,
    title: 'Scaling SaaS: Forging Excellence Through Fundamentals',
    description:
      'Introducing our latest research on the drivers of success behind SaaS companies',
    link: 'https://www.iconiqcapital.com/growth/insights/growth-and-efficiency',
    globalPriority: 4,
    localPriority: 1
  },
  {
    image: EnterpriseGrowthImage,
    title: 'The ICONIQ Growth Enterprise Five',
    description: 'Key performance indicators of SaaS companies',
    link: 'https://www.iconiqcapital.com/growth/insights/iconiq-growth-enterprise-five',
    globalPriority: 4,
    localPriority: 2
  }
];

export enum urlParamNames {
  COMPANY_STATUS = 'status',
  CURRENT_ARR = 'arr_or_revenue',
  YOY_ARR = 'yoy_arr_or_growth',
  FCF_MARGIN = 'fcf_margin',
  TOTAL_HEADCOUNT = 'headcount',
  COMPANY_WEBSITE = 'website',
  GROSS_MARGIN = 'gross_margin',
  NET_DOLLAR_RETENTION = 'net_dollor_retention',
  NET_MAGIC_NUMBER = 'magic_number',
  BURN_MULTIPLE = 'burn_multiple',
  QUICK_RATIO = 'quick_ratio',
  COMPANY_COLOR = 'company_color',
  RD = 'opex_rd',
  GA = 'opex_ga',
  SM = 'opex_sm',
  RD_HEAD = 'headcount_rd',
  GA_HEAD = 'headcount_ga',
  SM_HEAD = 'headcount_sm',
  EMAIL = 'email'
}

export const fileTypes = ['image/png', 'image/jpeg', 'image/jpg'];

export const walkthroughSteps = [
  {
    target: '.filters',
    content:
      'Explore the data from different software sectors, growth motions and primary customer segments.',
    disableBeacon: true,
    disableScrolling: true
  },
  {
    target: '.compare_performance',
    content:
      "By clicking here you're able to add your company's data, then overlay it with Compass SaaS benchmarks.",
    disableBeacon: true
  },
  {
    target: '.tabs_list',
    content:
      'Easily navigate through 11 different charts from the left side panel.',
    disableBeacon: true,
    placement: 'right-start' as const
  },
  {
    target: '.switch',
    content:
      'Switch seamlessly between Top Quartile and Median performance comparisons.',
    disableBeacon: true
  },
  {
    target: '.shareable_url',
    content:
      'Share your benchmarks! Click this button to copy a shareable URL to your clipboard.',
    disableBeacon: true,
    placement: 'left' as const
  },
  {
    target: '.articles',
    content:
      'Find articles on related trends, relevant insights, and key information.',
    disableBeacon: true
  }
];

export const dataModalWalkthroughSteps = [
  {
    target: '.required_info',
    content:
      "This data is required to overlay your company's data onto Compass SaaS benchmarks.",
    disableBeacon: true,
    disableScrolling: true
  },
  {
    target: '.other_info',
    content:
      'This data is optional, but adding it will allow you to see this data overlaid onto Compass SaaS benchmarks.',
    disableBeacon: true,
    disableScrolling: true
  },
  {
    target: '.email_entry',
    content: 'Submit your information by inputting your email address.',
    disableBeacon: true,
    disableScrolling: true
  }
];
