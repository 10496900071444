import { Chart } from 'chart.js';

export const imagePlugin = {
  id: 'customImagePlugin',
  beforeDraw: (chart: Chart) => {
    const { ctx } = chart;
    const logoURL = (chart.options?.plugins as any)?.customImagePlugin
      ?.companyLogoURL;
    const hasUpperValue = (chart.options?.plugins as any)?.customImagePlugin
      ?.hasUpperValue;
    const myCompanyDataIndex = (chart.options?.plugins as any)
      ?.customImagePlugin?.index;

    if (!logoURL) return;

    const myCompanyData = chart.data.datasets[myCompanyDataIndex]?.data || null;
    if (!myCompanyData) return;

    const valueIndex = myCompanyData.findIndex(
      (val): val is number => typeof val === 'number' && val !== 0
    );
    if (valueIndex === -1) return;

    const value = myCompanyData[valueIndex] as number;
    const barElement = chart.getDatasetMeta(myCompanyDataIndex).data[
      valueIndex
    ] as any;
    if (!barElement) return;

    const barWidth = barElement.width;
    const { x: xPosition, y: barTop, height: barHeight } = barElement;

    const image = new Image();
    image.src = logoURL;

    const drawImage = () => {
      // Calculate dimensions maintaining aspect ratio
      const aspectRatio = image.width / image.height;
      const maxWidth = barWidth - 40;

      // Calculate dimensions to fit image within max width while maintaining aspect ratio
      let drawWidth, drawHeight;
      if (aspectRatio > 1) {
        // Image is wider than tall
        drawWidth = maxWidth;
        drawHeight = maxWidth / aspectRatio;
      } else {
        // Image is taller than wide
        drawHeight = maxWidth;
        drawWidth = maxWidth * aspectRatio;
      }

      // Calculate vertical position based on image height
      const verticalOffset = hasUpperValue ? drawHeight + 40 : drawHeight + 32;
      const yPosition = value > 0
        ? barTop - verticalOffset
        : barTop - barHeight - verticalOffset;

      // Center image horizontally
      const x = xPosition - drawWidth / 2;
      const y = yPosition;

      // Draw image
      ctx.save();
      ctx.globalAlpha = 1;
      ctx.drawImage(image, x, y, drawWidth, drawHeight);
      ctx.restore();
    };

    if (image.complete) {
      drawImage();
    } else {
      image.onload = drawImage;
    }
  }
};
