import { useCallback, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import useClickOutside from './useClickOutside';
import { IColorPicker } from 'interfaces/components/modalTextField';

const ColorPicker = ({ color, onChange }: IColorPicker) => {
  const popover: any = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <div className='relative'>
      <div
        className='h-11 w-11 rounded-lg border-[3px] border-solid border-gray-400 cursor-pointer'
        style={{ backgroundColor: color }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div
          className='absolute top-[-200px] left-[100%] rounded-lg z-[200]'
          ref={popover}
        >
          <HexColorPicker color={color} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
