import Chart from 'chart.js/auto';
import {
  ChoroplethController,
  ColorScale,
  GeoFeature,
  ProjectionScale
} from 'chartjs-chart-geo';
import React, { Suspense, useEffect, useRef } from 'react';
import { feature } from 'topojson-client';
import json from '../../utils/json/world-atlas.json';
import { useDarkMode } from 'utils/hooks/DarkMode';

Chart.register(ChoroplethController, ColorScale, GeoFeature, ProjectionScale);

const GeoChartComponent: React.FC = () => {
  const isDarkMode = useDarkMode('(prefers-color-scheme: dark)');

  const chartInstanceRef = useRef<Chart | null>(null);

  useEffect(() => {
    const canvas = document.getElementById(
      'canvas-geo-world'
    ) as HTMLCanvasElement | null;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const dataJson: any = json;

        const countries = (feature(dataJson, dataJson.objects.countries) as any)
          .features;

        const regionData: { [key: string]: number } = {
          'North America': 86,
          Europe: 8,
          APAC: 6
        };

        const regionCountries: { [key: string]: string[] } = {
          'North America': ['Canada', 'United States of America', 'Mexico'],
          Europe: [
            'Albania',
            'Andorra',
            'Armenia',
            'Austria',
            'Azerbaijan',
            'Belarus',
            'Belgium',
            'Bosnia and Herzegovina',
            'Bulgaria',
            'Croatia',
            'Cyprus',
            'Czechia',
            'Denmark',
            'Estonia',
            'Finland',
            'France',
            'Georgia',
            'Germany',
            'Greece',
            'Hungary',
            'Iceland',
            'Ireland',
            'Italy',
            'Kazakhstan',
            'Kosovo',
            'Latvia',
            'Liechtenstein',
            'Lithuania',
            'Luxembourg',
            'Malta',
            'Moldova',
            'Monaco',
            'Montenegro',
            'Netherlands',
            'North Macedonia',
            'Norway',
            'Poland',
            'Portugal',
            'Romania',
            'Russia',
            'San Marino',
            'Serbia',
            'Slovakia',
            'Slovenia',
            'Spain',
            'Sweden',
            'Switzerland',
            'Turkey',
            'Ukraine',
            'United Kingdom',
            'Vatican'
          ],
          APAC: [
            'Australia',
            'Bangladesh',
            'Bhutan',
            'Brunei',
            'Cambodia',
            'China',
            'Fiji',
            'India',
            'Indonesia',
            'Japan',
            'Kiribati',
            'Laos',
            'Malaysia',
            'Maldives',
            'Marshall Islands',
            'Micronesia',
            'Mongolia',
            'Myanmar',
            'Nauru',
            'Nepal',
            'New Zealand',
            'North Korea',
            'Pakistan',
            'Palau',
            'Papua New Guinea',
            'Philippines',
            'Samoa',
            'Singapore',
            'Solomon Islands',
            'South Korea',
            'Sri Lanka',
            'Thailand',
            'Timor-Leste',
            'Tonga',
            'Tuvalu',
            'Vanuatu',
            'Vietnam'
          ]
        };

        const regionColors: { [key: string]: string } = {
          'North America': '#033A15',
          Europe: '#4DB96B',
          APAC: '#D5F0DD'
        };

        const getRegionValue = (name: string): number => {
          for (const region in regionCountries) {
            if (regionCountries[region].includes(name)) {
              return regionData[region];
            }
          }
          return 0;
        };

        const getRegionColor = (name: string): string => {
          for (const region in regionCountries) {
            if (regionCountries[region].includes(name)) {
              return regionColors[region];
            }
          }
          return '#ccc';
        };

        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy();
        }

        chartInstanceRef.current = new Chart(ctx, {
          type: 'choropleth',
          data: {
            labels: countries.map((d: any) => d.properties.name),
            datasets: [
              {
                label: 'Regions',
                data: countries.map((d: any) => ({
                  feature: d,
                  value: getRegionValue(d.properties.name)
                })),
                backgroundColor: countries.map((d: any) =>
                  getRegionColor(d.properties.name)
                )
              }
            ]
          },
          options: {
            showOutline: true,
            showGraticule: false,
            maintainAspectRatio: false,
            responsive: true,
            layout: {
              padding: {
                top: 0,
                right: 10,
                bottom: 30,
                left: 10
              }
            },
            plugins: {
              legend: {
                display: false,
                position: 'bottom',
                onClick: () => {},

                labels: {
                  font: { size: 12, family: 'Iconiq Quadraat' }
                }
              },

              datalabels: {
                align: 'top',
                formatter: v => {
                  return '';
                }
              },
              tooltip: {
                enabled: false,
                callbacks: {
                  label: context => {
                    const countryName = (context.raw as any).feature.properties
                      .name;
                    const value = (context.raw as any).value;
                    return `${countryName}: ${value}%`;
                  }
                }
              }
            },
            elements: {
              point: {
                radius: 5
              }
            },
            scales: {
              projection: {
                axis: 'x',
                projection: 'equalEarth'
              },
              size: {
                display: false,
                axis: 'x'
              },

              color: {
                axis: 'x',

                interpolate: v => {
                  if (v < 0.33) return regionColors['APAC'];
                  if (v < 0.66) return regionColors['Europe'];
                  return regionColors['North America'];
                },
                legend: {
                  position: 'bottom',
                  align: 'bottom',
                  length: 80
                },
                ticks: {
                  color: isDarkMode ? 'white' : 'black',
                  padding: 8
                }
              }
            }
          }
        });
      }
    }

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [isDarkMode]);

  return <canvas id='canvas-geo-world' />;
};

const GeoChart = React.memo(GeoChartComponent);

const GeoChartWrapper = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <GeoChart />
    </Suspense>
  );
};

export default GeoChartWrapper;
