export const getCustomOptions = (
  companyLogoURL: string,
  generatingPDF: boolean,
  showAsPercentage: boolean,
  showAsDollar?: boolean,
  hasUpperValue: boolean = true
): any => {
  const isDarkMode = generatingPDF
    ? false
    : window.matchMedia('(prefers-color-scheme: dark)').matches;

  return {
    responsive: true,
    maintainAspectRatio: false,
    skipNull: true,
    plugins: {
      legend: {
        onClick: () => {},
        position: 'bottom',
        align: 'start',
        labels: {
          font: {
            family: 'Iconiq Quadraat',
            size: 16
          },
          color: isDarkMode ? 'white' : 'black',
          borderRadius: 0,
          pointStyle: 'rect',
          usePointStyle: true,
          padding: 16
        }
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const formattedValue = parseFloat(context.raw);
            if (context && showAsPercentage) {
              return `${context.dataset.label}: ${formattedValue.toFixed(0)}%`;
            } else if (showAsDollar) {
              return `${context.dataset.label}: $${formattedValue.toFixed(2)}k`;
            } else {
              return `${context.dataset.label}: ${formattedValue.toFixed(1)}x`;
            }
          }
        }
      },
      datalabels: {
        anchor: 'end',
        align: 'end',

        formatter: (value: any) => {
          if (value && showAsPercentage) {
            return `${value?.toFixed(0)}%`;
          } else if (value && showAsDollar) {
            return `$${value}k`;
          } else {
            return value && `${value?.toFixed(1)}x`;
          }
        },
        font: {
          family: 'Iconiq Quadraat',
          size: 16
        },
        color: isDarkMode ? 'white' : 'black'
      },
      customImagePlugin: {
        companyLogoURL,
        index: 1,
        hasUpperValue: hasUpperValue
      }
    },
    layout: {
      padding: {
        top: companyLogoURL ? 90 : 25
      }
    },
    datasets: {
      bar: {
        barThickness: 90,
        categoryPercentage: 1.0,
        barPercentage: 1.0
      }
    },
    scales: {
      x: {
        grid: {
          drawTicks: true,
          display: false
        },
        title: {
          display: true,
          text: 'ARR Range',
          font: {
            family: 'Iconiq Quadraat',
            size: 12
          },
          color: isDarkMode ? 'white' : 'black'
        },
        ticks: {
          display: true,
          font: {
            weight: 'normal',
            family: 'Iconiq Quadraat',
            size: 16
          },
          color: isDarkMode ? 'white' : 'black'
        },
        border: {
          display: false
        }
      },
      y: {
        grid: {
          drawTicks: false,
          display: false
        },
        ticks: {
          display: false
        },
        border: {
          display: false
        }
      }
    }
  };
};
