import LockIcon from 'assets/icons/lock.svg';
import { DashboardPdf } from 'components/dashboardPdf';
import CustomTooltip from 'components/Tooltip';
import { IChartData } from 'interfaces/chartData';
import { FilterOption } from 'interfaces/components/filter';
import 'react-tooltip/dist/react-tooltip.css';
import { getPreviousQuarter } from 'utils/helpers';
import LogoIcon from './LogoIcon';
import WhiteLogoIcon from './WhiteLogoIcon';
import { Switch } from './Switch';
import HelpIcon from 'assets/icons/help-grey.svg';
import LighterGreyHelpIcon from 'assets/icons/help-lighter-grey.svg';

interface ChartContainerProps {
  title: string;
  tooltip: string;
  onDownload: () => void;
  onDownloadDone: () => void;
  switchChecked: boolean;
  onSwitchChange: (checked: boolean) => void;
  children: React.ReactNode;
  chartData: IChartData[];
  downloadIndex: number;
  isCompanyDataEntered: boolean;
  arr: FilterOption[];
  growthMotion: FilterOption[];
  softwareSector: FilterOption[];
  primaryCustomer: FilterOption[];
  loading: boolean;
}

const ChartContainer: React.FC<ChartContainerProps> = ({
  title,
  onDownload,
  switchChecked,
  onSwitchChange,
  children,
  chartData,
  onDownloadDone,
  downloadIndex,
  isCompanyDataEntered,
  arr,
  growthMotion,
  softwareSector,
  primaryCustomer,
  loading,
  tooltip
}) => {
  const formulas: { [key: string]: any } = {
    'YoY ARR Growth': 'Year 2 ARR / Year 1 ARR - 1',
    'Net Dollar Retention':
      '1 + (Expansion ARR - Gross Churn ARR) / Average (BOP ARR + EOP ARR)',
    'Net Magic Number': 'Net New ARR / S&M Expense',
    'Gross Margin': 'Gross Profit / Revenue',
    'Rule of 40': 'ARR Growth YoY % + FCF Margin %',
    'Burn Multiple': 'Net Burn / Net New ARR',
    'ARR per FTE': 'ARR / Total Headcount',
    'OpEx as a % of Revenue': (
      <>
        <span className='underline'>Median</span> OpEx by Function / Revenue
      </>
    ),
    'FCF Margin': 'FCF / Revenue',
    'Quick Ratio': 'Gross New ARR / Gross Churned ARR',
    'Headcount Distribution': (
      <>
        <span className='underline'>Average</span> Headcount by Function / Total
        Company Headcount (excluding Cost of Revenue FTEs)
      </>
    )
  };

  const hidingSwitch = ['Headcount Distribution', 'OpEx as a % of Revenue'];
  const isHidden = hidingSwitch.some(label => label === title);
  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

  return (
    <>
      <div className='flex justify-between items-center'>
        <div className='flex items-center gap-2'>
          <p className='text-[28px] font-light'>{title}</p>
          <CustomTooltip
            id='info-chart'
            content={tooltip}
            icon={isDarkMode ? LighterGreyHelpIcon : HelpIcon}
            className='w-7 h-7'
          />
        </div>

        <DashboardPdf
          onDownload={onDownload}
          onDownloadDone={onDownloadDone}
          chartData={chartData}
          arr={arr}
          growthMotion={growthMotion}
          softwareSector={softwareSector}
          primaryCustomer={primaryCustomer}
          loading={loading}
          isCompanyDataEntered={isCompanyDataEntered}
        />
      </div>

      <span className='text-[14px] text-gray-700 dark:text-white mt-2'>
        {formulas[title]}
      </span>

      {!isHidden && (
        <div className='flex items-center mt-[10px] switch w-fit'>
          <span className='font-light leading-[18.40px]'>Top Quartile</span>
          <Switch
            id={`${title}'_top-quartile_median'`}
            className='ml-2'
            checked={switchChecked}
            onCheckedChange={onSwitchChange}
            // TODO: button temporarily unlocked
            // disabled={!isEntered}
          />
          <label
            className={`font-light leading-[18.40px] ml-2 mr-1 ${
              switchChecked ? '' : 'text-gray-500'
            }  `}
            htmlFor='top-quartile'
          >
            Median
          </label>
          <CustomTooltip
            icon={LockIcon}
            id='median_lock'
            content='You can unlock this feature and view median benchmarks by accessing the Compare Your Benchmarks modal above.'
            className={`hidden w-4 h-4`}
          />
        </div>
      )}

      <div className='w-full h-[370px] mt-[10px]'>{children}</div>

      <hr className='mt-4' />

      <div className='w-full flex justify-between items-center mt-3'>
        <span className='text-gray-700 dark:text-white text-lg font-light leading-snug'>
          {`Data last refreshed as of ${getPreviousQuarter()}`}
        </span>
        <div className='w-fit'>
          {isDarkMode ? <WhiteLogoIcon /> : <LogoIcon />}
        </div>
      </div>
      <div className='w-full flex  mt-4'>
        <p className='text-xs font-light text-gray-700 dark:text-white'>
          The numbers, figures and case studies included in this tool have been
          included for purposes of illustration only, and no assurance can be
          given that the actual results achieved by any ICONIQ portfolio or any
          of ICONIQ's partners or affiliates will correspond with the results
          contemplated in the tool. Past performance is not a reliable indicator
          of future performance, and any investment may result in the loss of
          all or some of the amounts invested.
        </p>
      </div>
    </>
  );
};

export default ChartContainer;
