import {
  FilterOption,
  IDefaultItemRendererProps,
  IFilter
} from 'interfaces/components/filter';
import { MultiSelect } from 'react-multi-select-component';
import './filter.css';
import DownIcon from 'assets/icons/down.svg';
import DarkDownIcon from 'assets/icons/down-white.svg';
import { forwardRef, MutableRefObject } from 'react';

export const Filter = forwardRef(
  ({ label, options, value, onChange, id }: IFilter, ref) => {
    const customValueRenderer = (selected: FilterOption[]) => {
      if (selected.length === 0) {
        return 'Select';
      } else if (selected.length === options.length) {
        return 'All';
      } else if (selected.length === 1) {
        return selected[0].label;
      } else {
        return `${selected.length} selected`;
      }
    };

    const customItemRenderer = ({
      checked,
      option,
      onClick,
      disabled
    }: IDefaultItemRendererProps) => (
      <div
        className={`item-renderer ${
          disabled ? 'disabled' : ''
        } flex flex-row !items-center justify-start gap-4`}
      >
        <input
          className='checkbox accent-green-300 bg-white dark:bg-black !mr-0 text-lg font-light text-left'
          type='checkbox'
          onChange={onClick}
          checked={checked}
          tabIndex={-1}
          disabled={disabled}
        />
        <span className='text-lg font-light text-left'>
          {option.label === 'Select All' ? 'All' : option.label}
        </span>
      </div>
    );

    return (
      <div
        className='flex flex-col items-start gap-2 w-[375px] z-10'
        ref={ref as MutableRefObject<HTMLInputElement>}
      >
        <p className='font-base font-light'>{label}</p>
        <MultiSelect
          options={options}
          overrideStrings={{ selectSomeItems: 'Select' }}
          value={value}
          onChange={onChange}
          ArrowRenderer={() => (
            <picture>
              <source
                srcSet={DarkDownIcon}
                media='(prefers-color-scheme: dark)'
              />
              <img src={DownIcon} alt='' className='w-5 h-5' />
            </picture>
          )}
          ClearSelectedIcon={null}
          disableSearch
          valueRenderer={customValueRenderer}
          ItemRenderer={customItemRenderer}
          labelledBy='Select'
        />
        <input
          type='text'
          value={JSON.stringify(value)}
          className='hidden'
          id={id}
          readOnly
        />
      </div>
    );
  }
);
