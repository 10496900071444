import CustomTooltip from 'components/Tooltip';
import { ICalculatedField } from 'interfaces/components/calculatedField';
import HelpIcon from 'assets/icons/help-black.svg';
import WhiteHelpIcon from 'assets/icons/help-white.svg';
import { formatMoney } from 'utils/helpers';
import { useState } from 'react';
import ConfirmWarningIcon from 'assets/icons/confirm_warning.svg';

const CalculatedField = ({
  label,
  tooltip,
  value,
  character,
  isMoney,
  warning
}: ICalculatedField) => {
  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [isWarningConfirmed, setIsWarningConfirmed] = useState(false);

  const onWarningConfirmed = () => {
    setIsWarningConfirmed(true);
  };

  return (
    <div className='flex flex-col items-start justify-start gap-2 flex-1'>
      <div className='w-full flex flex-row items-center justify-between h-11 rounded-[4px] px-4 py-2 bg-gray-200 dark:bg-gray-900'>
        <div className='flex flex-row items-center gap-1'>
          <p className='text-base font-light'>{label}</p>
          {!!tooltip && (
            <CustomTooltip
              id={label}
              content={tooltip}
              icon={isDarkMode ? WhiteHelpIcon : HelpIcon}
              className='w-4 h-4'
            />
          )}
        </div>

        <p className='text-lg font-light'>
          {isMoney
            ? character + ' ' + formatMoney(value, 2)
            : value + '' + character}
        </p>
      </div>
      {warning && !isWarningConfirmed && (
        <div className='flex flex-row items-center gap-2'>
          <p className='text-sm font-light'>{warning}</p>
          <img
            src={ConfirmWarningIcon}
            alt=''
            className='cursor-pointer'
            onClick={onWarningConfirmed}
          />
        </div>
      )}
    </div>
  );
};

export default CalculatedField;
