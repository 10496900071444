import { IHalfWidthSection } from 'interfaces/components/halfWidthSection';

const HalfWidthSection = ({
  title,
  icon,
  description,
  children
}: IHalfWidthSection) => {
  return (
    <div className='flex-1 flex flex-col justify-start items-center bg-transparent'>
      <div className='flex flex-col items-start gap-4'>
        <div className='flex flex-row gap-2 w-[100%]'>
          {icon && <img src={icon} alt='' />}
          <p className='text-3xl font-light'>{title}</p>
        </div>
        <p className='text-lg font-normal leading-[25.2px] text-left font-ebGaramond text-gray-700 dark:text-white'>
          {description}
        </p>
      </div>
      <div className='grid grid-cols-2 gap-6 w-full mt-10 h-full'>
        {children}
      </div>
    </div>
  );
};

export default HalfWidthSection;
