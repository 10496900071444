
// Logarithmic Calculation
//  1. Calc logarithm of n base 1000 (Math.log(n) / Math.log(1000)).
//     - gives us the exponent telling which suffix to use (i.e., how many times you can divide n by 1000 before it becomes less than 1).
//  2. Do Math.floor on the logarithmic result to give an index to the correct entry in the suffixes array.
//  3. Divide n by 1000 raised to that order.
//     - This scales n down appropriately to a value between 1 and 1000.
//  4. Scaled number is rounded and formatted to the desired number of decimal places, and suffix is added.
export function formatMoney(n: number, d: number) {
  if (n < 1) return n.toFixed(d);                                 // immediately return if number < 1

  const e = Math.pow(10, d);
  const suffixes = ['', 'k', 'M', 'B', 'T', 'P', 'E'];            // Abbreviations
  const order = Math.min(Math.floor(Math.log(n) / Math.log(1000)), suffixes.length - 1);
  
  // Adjust n by dividing by 1000^order to get the scaled number
  const scaled = n / Math.pow(1000, order);

  // Round the scaled number to the specified number of decimal places and add the right suffix
  return (Math.round(scaled * e) / e).toFixed(d) + suffixes[order];
}

export function formatThousands(n: number, d: number) {
  // let valueInThousands  = n / 1000;                             // Convert the number to thousands
  // let roundedValue      = valueInThousands.toFixed(d);          // Round the number to the specified decimal places    
  // let formattedValue    = parseFloat(roundedValue).toString();  // Remove trailing zeros and unnecessary decimal point; (.toString() not needed here)

  // return `${formattedValue} k`;                                 // Append 'k' to denote thousands
  return `${parseFloat( (n / 1000).toFixed(d) )} k`;
}

export function getCurrentDateFormatted() {
  const today = new Date();
  return today.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).replace(/\//g, '-');
}

export function getPreviousQuarter() {
  const quarter = process.env.ICONIQ_QUATRER || 'Q3 2024'; // Default to Q3 2024 if ICONIQ_QUATRER is not set
/*   const currentDate = new Date();
  const month = currentDate.getMonth();                         // Note: month is 0-indexed (0 = January, 11 = December)
  const year = currentDate.getFullYear();

  let previousQuarter, previousQuarterYear;

  // Determine the previous quarter based on the current month
  if (month >= 0 && month <= 2) {                               // Jan to Mar
    previousQuarter = 'Q4';
    previousQuarterYear = year - 1;                           // Previous year
  } else if (month >= 3 && month <= 5) {                        // Apr to Jun
    previousQuarter = 'Q1';
    previousQuarterYear = year;
  } else if (month >= 6 && month <= 8) {                        // Jul to Sep
    previousQuarter = 'Q2';
    previousQuarterYear = year;
  } else {                                                      // Oct to Dec
    previousQuarter = 'Q3';
    previousQuarterYear = year;
  } */

    return `${quarter}`;  
  // Return the string in the format 'QQ YYYY'
 // return `${previousQuarter} ${previousQuarterYear}`;
}

export function ensureHttpProtocol(url: string) {
  // Check if the URL starts with "http://" or "https://"
  if (!/^https?:\/\//i.test(url)) {
    // Prepend "https://" to the URL if it does not start with "http://" or "https://"
    url = 'https://' + url;
  }
  return url;
}

export function truncateAndEllipsis(str: string, length: number) {
  return str.length > length ? str.slice(0, length - 3) + '...' : str;
}